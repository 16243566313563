import React, { useContext } from 'react';
import BackInTimeImg from '../assets/images/back-in-time.png';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import DatePick from './DatePick';
import UserContext from '../context/userContext';
import { multiTrigger } from '../services/api';

function BackInTimes({ firstScreen = false }) {
  const [user, setUser] = useContext(UserContext);

  let navigate = useNavigate();
  const setupLaterHandler = () => {
    localStorage.setItem('time', true);
    navigate('/');
  };

  const syncOrdersHandler = () => {
    const from = format(user.startDate, 'dd-MM-yyyy');
    const to = format(new Date(), 'dd-MM-yyyy');

    multiTrigger(from, to).then((response) => {
      if (response.status === 200) {
        let date = new Date();
        date.setTime(date.getTime() + 15 * 60 * 1000);
        localStorage.setItem('sync_interval', date);
      }
    });
  };

  return (
    <div className="mx-auto max-w-sm text-center">
      <h3 className="text-xl font-bold text-[#222429]">Back In Time</h3>
      <img className="mx-auto mt-3.5" src={BackInTimeImg} alt="" />
      <p className="mt-4 text-sm text-[#8FA1BF]">
        Sync Orders Already Fulfilled
      </p>
      <p className="mt-3.5 text-sm text-[#566381]">
        Back in time feature can retrieve up to 100.000 orders prior to
        installing PalTrack. Click below and choose a date from which you would
        like to start data retrieval process.
      </p>
      <div className="mt-10">
        <DatePick
          startDate={user.startDate}
          setStartDate={(date) =>
            setUser((prev) => ({ ...prev, startDate: date }))
          }
        />
      </div>
      <button
        type="button"
        className="text-white py-1 px-4 bg-primary-200 rounded-md w-full md:w-[370px] hover:opacity-90 text-sm leading-4 font-semibold h-11 mt-6 disabled:bg-gray-300 disabled:hover:opacity-100 disabled:cursor-not-allowed"
        disabled={user.syncDisabled}
        onClick={syncOrdersHandler}
      >
        Sync Orders from {format(user.startDate, 'dd MMM yyyy')}
      </button>

      <p className="mt-3.5 text-xs text-[#566381]">
        This feature will always be available at your command in the navigation
        bar if you choose to skip it for now
      </p>

      {firstScreen && (
        <button
          className="mt-1.5 rounded-md text-sm leading-4 text-[#F8F7F7] py-3.5 px-10 bg-[#8FA1BF]"
          type="button"
          onClick={setupLaterHandler}
        >
          Set up Later
        </button>
      )}
    </div>
  );
}

export default BackInTimes;
