import React, { useState, useEffect } from "react";
import {
  getParamsFromUrlString,
  isEmpty,
  saveStorage,
  getStorage,
} from "../utils";
import { getAuthLink, getToken } from "../services/api";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function Auth() {
  const [message, setMessage] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    const shop = getParamsFromUrlString("shop");
    const code = getParamsFromUrlString("code");

    // Return to the homepage if we have token
    if (!isEmpty(getStorage("token"))) return navigate("/");

    // Break if the shop name is empty
    if (isEmpty(shop)) return setMessage("Shop name is empty!");

    // Get link if the user don't have a token
    if (isEmpty(code)) {
      getAuthLink(shop).then((response) => {
        window.location.href = response.data.link;
        return;
      });
    }

    // Get token if we have codeParam
    getToken({ code, shop }).then((response) => {
      saveStorage("token", response.data.token);
      navigate("/");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-screen flex items-center justify-center">
      {message ? message : <Loader />}
    </div>
  );
}

export default Auth;
