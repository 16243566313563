import React from "react";
import BackInTimes from "../components/BackInTimes";

function BackInTime() {
  return (
    <div className="card max-w-6xl mx-auto pt-14 pb-8 h-full flex items-center">
      <BackInTimes firstScreen={true} />
    </div>
  );
}

export default BackInTime;
