import React from "react";
import PaypalConnect from "../components/PaypalConnect";
import { getPaypalSignInLink } from "../services/api";

function SettingPage() {
  const paypalConnectHandler = () => {
    getPaypalSignInLink().then((response) => {
      window.location.replace(response.data.link);
    });
  };
  return (
    <>
      <h2 className="text-2xl font-medium">Paypal Settings</h2>
      <div className="card mt-3.5 py-10">
        <PaypalConnect onConnect={paypalConnectHandler} />
      </div>
    </>
  );
}

export default SettingPage;
