export const REACT_APP_SHOPIFY_AUTH_REDIRECT =
  process.env.REACT_APP_SHOPIFY_AUTH_REDIRECT ||
  `${window.location.origin}/auth`;

export const REACT_APP_SHOPIFY_BILLING_REDIRECT =
  process.env.REACT_APP_SHOPIFY_BILLING_REDIRECT ||
  `${window.location.origin}/`;

export const REACT_APP_PAYPAL_REDIRECT_PAGE =
  process.env.REACT_APP_PAYPAL_REDIRECT_PAGE || `${window.location.origin}`;
