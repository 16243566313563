import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Alert from '../components/Alert';
import DateRange from '../components/DateRange';
import TrackCard from '../components/TrackCard';
import TrackItem from '../components/TrackItem';
import Empty from '../components/Empty';
import { GoSync } from 'react-icons/go';
import DatePick from '../components/DatePick';
import { format } from 'date-fns';
import UserContext from '../context/userContext';
import { isEmpty, getStorage } from '../utils';
import liveIcon from '../assets/images/live-icon.svg';
import {
  getOrders,
  getOrdersError,
  getPaypalSignInLink,
  multiTrigger,
} from '../services/api';

import alertIcon from '../assets/images/alert-icon.png';

function DashboardPage() {
  const [user, setUser] = useContext(UserContext);

  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    getOrdersError().then((response) => {
      if (response.data.orders) setAlerts(response.data.orders);
    });
    getOrders().then((response) => {
      setUser((prev) => ({ ...prev, orders: response.data }));
    });
  }, []);

  const syncOrdersHandler = () => {
    const from = format(user.startDate, 'dd-MM-yyyy');
    const to = format(new Date(), 'dd-MM-yyyy');

    multiTrigger(from, to).then((response) => {
      if (response.status === 200) {
        let date = new Date();
        date.setTime(date.getTime() + 15 * 60 * 1000);
        localStorage.setItem('sync_interval', date);
      }
    });
  };

  const paypalConnectHandler = async () => {
    const response = await getPaypalSignInLink(getStorage('token'));
    window.location.href = response.data.link;
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3">
        <h2 className="text-2xl font-medium">Dashboard</h2>
        <div className="flex items-center justify-end flex-1 space-x-6">
          {isEmpty(user.paypal_email) ? (
            <Alert
              message="Your PayPal account is not correctly configured. Go to Setting."
              variant="danger"
            />
          ) : (
            <Alert
              message="Your PayPal account is successfully connected. Happy syncing 🎉"
              variant="success"
            />
          )}

          <div>
            <DateRange />
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <h2 className="text-lg leading-6 ">Sync Tracking Numbers </h2>
        <div className="grid  xl:grid-cols-[1fr,1fr,384px] gap-7 mt-4 md:mt-6">
          <div className="space-y-8 flex flex-col">
            <TrackCard
              title="Fulfilled orders"
              amount={user.ordersStats && user.ordersStats.submitted}
              range={user.ordersStatsRange && user.ordersStatsRange.title}
              img={require('../assets/images/shopify-card.png')}
            />
            {user.syncDisabled && alerts ? (
              <div className="rounded-md border border-[#CDD0E3] border-opacity-75 px-4 py-6 h-full flex  flex-col">
                <h3 className="text-base font-medium">
                  Alerts
                  <span className="text-[#FF0000] ml-1">({alerts.length})</span>
                </h3>

                {alerts.length !== 0 ? (
                  <div className="space-y-5 mt-5 flex-1 max-h-[282px] overflow-auto">
                    {alerts.map((alert, index) => {
                      return (
                        <div
                          className="bg-[#F5F7F8] py-2.5 px-3 rounded-md"
                          key={index}
                        >
                          <div className="flex space-x-4">
                            <img
                              className="w-6 h-6 flex-shrink-0"
                              src={alertIcon}
                              alt=""
                            />

                            <div className="flex-1">
                              <div className="flex justify-between items-center">
                                <p className="text-sm font-semibold text-[#212B36]">
                                  {alert.order_id}
                                </p>
                                <p className="text-xs text-[#212B36]">
                                  {alert.order_name}
                                </p>
                              </div>
                              <a
                                className="underline text-sm text-[#FF0000]"
                                href={`https://${user.name}/admin/orders/${alert.order_id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {alert.error_message}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex flex-1 items-center justify-center">
                    <Empty />
                  </div>
                )}

                {alerts.length !== 0 && (
                  <div className="text-center mt-3">
                    <Link
                      to="/back-in-time"
                      type="button"
                      className="text-white py-1 px-4 bg-primary-200 rounded-md mx-auto w-[258px] hover:opacity-90 text-sm leading-4 font-semibold h-11 inline-flex items-center gap-3 justify-center"
                    >
                      <GoSync className="text-lg" />
                      <span> Retry Failed Orders</span>
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              <div className="rounded-md border border-[#CDD0E3] border-opacity-75 p-7 flex-1 text-center flex flex-col">
                <h4 className="text-xl font-bold text-[#222429] leading-6">
                  Back In Time
                </h4>
                <img
                  className="mt-3.5 w-16 h-16 mx-auto"
                  src={require('../assets/images/back-in-time.png')}
                  alt=""
                />
                <div className="flex-1 mt-4 space-y-3">
                  <p className="text-sm text-[#8FA1BF] ">
                    Sync Orders Already Fulfilled
                  </p>
                  <p className="text-sm text-[#566381] ">
                    Sync up to 100.000 orders prior to installing Paltrack.
                    going back to your chosen date.
                  </p>
                </div>
                <div className="mt-2">
                  <DatePick
                    startDate={user.startDate}
                    setStartDate={(date) =>
                      setUser((prev) => ({ ...prev, startDate: date }))
                    }
                  />
                </div>
                <button
                  type="button"
                  className="text-white py-1 px-4 bg-primary-200 rounded-md mt-4 w-full hover:opacity-90 text-sm leading-4 font-semibold h-11 disabled:bg-gray-300 disabled:hover:opacity-100 disabled:cursor-not-allowed"
                  onClick={syncOrdersHandler}
                  disabled={user.syncDisabled}
                >
                  Sync Orders from {format(user.startDate, 'dd MMMM yyyy')}
                </button>
              </div>
            )}
          </div>
          <div className="space-y-8 flex flex-col">
            <TrackCard
              title="Orders Synced To Paypal"
              amount={user.ordersStats && user.ordersStats.submitted}
              range={user.ordersStatsRange && user.ordersStatsRange.title}
              // todo : make percentage calculated
              percent=""
              img={require('../assets/images/paypal-card.png')}
            />
            <div className="rounded-md border border-[#CDD0E3] border-opacity-75 p-7 flex-1 text-center flex flex-col">
              <h4 className="text-xl font-bold text-[#222429] leading-6">
                {user?.paypal_email
                  ? 'Connected PayPal Account'
                  : 'Connect with PayPal'}
              </h4>
              <img
                className="mt-3.5 w-16 h-16 mx-auto"
                src={require('../assets/images/connect-paypal.png')}
                alt=""
              />
              <div className="flex-1 mt-4 space-y-3">
                {user?.paypal_email ? (
                  <p className="text-sm text-[#8FA1BF] ">
                    Automatically syncing your Orders to PayPal.
                  </p>
                ) : (
                  <>
                    <p className="text-sm text-[#8FA1BF] ">
                      Link Your Paypal For Automatic Trackings Sync
                    </p>
                    <p className="text-sm text-[#566381]">
                      We will receive access limited to adding tracking details
                      only. The connection is verified and secured by PayPal and
                      Shopify. Click the button below to connect your PayPal
                      account.
                    </p>
                  </>
                )}

                {user?.paypal_email && (
                  <>
                    <svg
                      className="mt-3 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.319"
                      height="25.319"
                    >
                      <g data-name="Group 49776">
                        <path
                          d="M12.659 25.319A12.659 12.659 0 1 0 0 12.659a12.659 12.659 0 0 0 12.659 12.66Z"
                          fill="#00d8ae"
                        />
                        <path
                          data-name="Path"
                          d="M17.135 8.184a1.266 1.266 0 1 1 1.79 1.79l-7.157 7.157a1.266 1.266 0 0 1-1.79 0l-3.585-3.576a1.266 1.266 0 0 1 1.79-1.79l2.686 2.685Z"
                          fill="#f8f7f7"
                        />
                      </g>
                    </svg>
                    <div className="bg-[#F4F3FF] w-full text-primary font-medium rounded-md py-4 mt-3.5">
                      {user?.paypal_email}
                    </div>
                  </>
                )}
              </div>

              <button
                type="button"
                className="text-white py-1 px-4 bg-primary-100 rounded-md mt-4 w-full hover:opacity-90 text-sm leading-4 font-semibold flex items-center space-x-5  justify-center h-11"
                onClick={paypalConnectHandler}
              >
                <img src={require('../assets/images/paypal-logo.png')} alt="" />
                <span>
                  {user?.paypal_email
                    ? 'Change PayPal Account'
                    : 'Connect With PayPal'}
                </span>
              </button>
            </div>
          </div>
          <div className="rounded-md p-2 pt-3.5 bg-white shadow border border-[#CDD0E3] flex flex-col">
            <div className="flex items-center space-x-3">
              <img
                src={liveIcon}
                alt=""
                className="w-10 h-10 flex-shrink-0"
                style={{
                  animation: 'flash 2s cubic-bezier(0, 0, 0.2, 1) infinite',
                  transform: 'scale(0.8)',
                }}
              />
              <p className="text-lg font-medium text-[#696974]">
                Latest Orders Synced To Paypal
              </p>
            </div>
            <div className="mt-4 space-y-2 flex-1">
              {!user?.orders?.count ? (
                <div className="flex justify-center items-center h-full">
                  <Empty />
                </div>
              ) : (
                user?.orders.orders

                  .slice(0, 6)
                  .reverse()
                  .map((item, index) => (
                    <TrackItem
                      number={item.tracking_number}
                      carrier={item.carrier}
                      status={item.status}
                      key={index}
                    />
                  ))
              )}
            </div>
            <div className="text-center mt-2">
              <Link to="/orders" className="text-sm text-[#0058FF]">
                See More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardPage;
