import React from "react";
import FooterLogo from "../assets/images/footer-logo.svg";

function Footer() {
  return (
    <a href="https://blog.partnerswire.com/" className="flex justify-center">
      <img src={FooterLogo} alt="Powered by Partners Wire" />
    </a>
  );
}

export default Footer;
