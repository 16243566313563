import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { HiOutlineCalendar, HiChevronDown } from "react-icons/hi";

function DatePick({ startDate, setStartDate }) {
  const CustomButton = forwardRef(({ value, onClick }, ref) => (
    <div className="datepicker-button" onClick={onClick} ref={ref}>
      <div className="datepicker-button-icon-wrapper">
        <HiOutlineCalendar className="datepicker-button-icon" />
      </div>
      <div className="flex-1 text-center pr-5">
        <span className="datepicker-button-text">{value}</span>
        <HiChevronDown className="datepicker-button-chevron" />
      </div>
    </div>
  ));
  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      customInput={<CustomButton />}
      dateFormat="dd MMMM yyyy"
      popperClassName="datepicker"
    />
  );
}

export default DatePick;
