import React, { useState } from 'react';
import AppCard from './AppCard';

function RecommendedApps() {
  const [apps] = useState([
    {
      title: 'Uptosheets sync orders',
      subtitle: 'UpToSheets - connect orders data to Google Sheets',
      text: 'UpToSheets is a Google Sheets sync app developed to simplify the way you manage your Shopify store. This is the easiest to use Shopify to Google Sheets integration app.',
      link: 'https://apps.shopify.com/uptosheets',
      img: require('../assets/images/uptosheets.png'),
    },
    {
      title: 'Dropship China Pro Fulfilment',
      subtitle: 'Focus on marketing and sales and let us do the rest!',
      text: 'DCP Fulfillment integrates your supply chain elements into one dashboard to ease order fulfillment and inventory management while delivering your products cheaper, faster, and consistently.',
      link: 'https://apps.shopify.com/dropshipchinapro-fulfillment',
      img: require('../assets/images/DropshipChina.png'),
    },
    {
      title: 'UpPromote: Affiliate Marketing',
      subtitle: 'Grow With Ambassador, Influencer, referral & Affiliate Program ',
      text: "UpPromote is the top recommended affiliate/referral app on Shopify App Store. With this all-in-one solution, you can build and manage your marketing campaign with ease",
      link: 'https://apps.shopify.com/affliate-by-secomapp?utm_campaign=secomapp-partner&utm_medium=affiliate&utm_source=Partnerswire_Website',
      img: require('../assets/images/uppromote.webp'),
    },
    {
      title: 'CVox ‑ Email & SMS Marketing',
      subtitle:
        'Lading Page Builder, Popups, Email & SMS Bump Marketing & more',
      text: 'Plan, design, and build integrated customer experiences by leveraging personalized touchpoints towards delivering higher conversion rates, higher average order values (AOV), and increased customer loyalty.',
      link: 'https://platform.shoffi.app/v1/customervox/euxine-hteeM3lm',
      img: require('../assets/images/cvox.png'),
    },
  ]);

  return (
    <div className="rounded-md bg-gradient-to-b from-white to-transparent py-4 px-5 ">
      <h2 className="text-sm">Partners</h2>
      <div className="grid lg:grid-cols-2 2xl:grid-cols-4 gap-7 mt-6 max-w-8xl">
        {apps.map((card, index) => (
          <AppCard
            title={card.title}
            subtitle={card.subtitle}
            text={card.text}
            link={card.link}
            img={card.img}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

export default RecommendedApps;
