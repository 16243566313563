import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './css/index.css';
import App from './App';

import DashboardPage from './pages/DashboardPage';
import BackInTimePageMain from './pages/BackInTimePageMain';
import BackInTimePage from './pages/BackInTimePage';
import SettingsPage from './pages/SettingsPage';
import OrdersPage from './pages/OrdersPage';
import Auth from './pages/Auth';
import AutoRoutingPage from './pages/AutoRoutingPage';
import EmailReportsPage from './pages/EmailReportsPage';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<DashboardPage />} />
          <Route path="orders" element={<OrdersPage />} />
          <Route path="back-in-time-main" element={<BackInTimePageMain />} />
          <Route path="back-in-time" element={<BackInTimePage />} />
          <Route path="auto-routing" element={<AutoRoutingPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="email-reports" element={<EmailReportsPage />} />
        </Route>

        <Route path="/auth" element={<Auth />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
