import React from "react";

function Loader() {
  return (
    <div className="animate-pulse">
      <svg xmlns="http://www.w3.org/2000/svg" width="146.755" height="40.185">
        <g data-name="Group 49520" fill="#371AEE">
          <path
            data-name="Path 44658"
            d="M34.9 0H6.467A6.469 6.469 0 0 0 0 6.467v27.252a6.466 6.466 0 0 0 6.467 6.467H34.9a6.466 6.466 0 0 0 6.467-6.467V6.467A6.469 6.469 0 0 0 34.9 0M23.959 30.014c0 .055 0 .11-.009.165a3.954 3.954 0 0 1-4.23 3.659 4.012 4.012 0 0 1-3.837-4.011v-4.601c0-1.459-.014-2.913 0-4.377a4.041 4.041 0 0 1 8.076-.2q.11 4.685 0 9.366m9.732-6.887a4 4 0 0 1-4.491 1.554 3.931 3.931 0 0 1-2.973-3.531 3.787 3.787 0 0 1 .8-2.881 2.2 2.2 0 0 0 .389-2.561 2.37 2.37 0 0 0-2.337-1.18c-2.657-.009-5.319 0-7.98 0v-.018c-2.552 0-5.113.014-7.674 0h-.246A3.9 3.9 0 0 1 5.41 10.5v-.183a3.984 3.984 0 0 1 4.011-3.951C15 6.348 20.57 6.329 26.15 6.357a10.317 10.317 0 0 1 7.541 16.77"
          />
          <path
            data-name="Path 44659"
            d="M55.136 10.474c-2-.021-4.007 0-6.011 0-.111 0-.222.016-.348.026v19.751h3.926v-.494c0-1.707.009-3.413-.007-5.12 0-.327.1-.415.417-.406q1.117.029 2.235 0a6.882 6.882 0 1 0-.212-13.756m2.843 8.021a2.812 2.812 0 0 1-2.766 2.1c-.74.046-1.487.007-2.232.021-.268 0-.279-.149-.278-.345v-5.053q0-.366-.007-.722c0-.29.09-.378.375-.365.744.021 1.489-.007 2.233.024a2.779 2.779 0 0 1 2.727 2.325 4.209 4.209 0 0 1-.051 2.014Z"
          />
          <path
            data-name="Path 44660"
            d="M143.729 23.863a.378.378 0 0 1 .084-.488 9.187 9.187 0 0 0 1.208-1.624 10.293 10.293 0 0 0 .907-4.984c0-.032-.033-.064-.068-.129-1.052 0-2.116.01-3.178-.007-.3 0-.347.108-.374.377a17.5 17.5 0 0 1-.347 2.621 2.452 2.452 0 0 1-2.943 1.835c-.052-.012-.1-.026-.155-.041V11.152c0-.811.081-.677-.7-.684-.861-.007-1.72 0-2.581 0h-.421v19.774c.122.012.222.028.321.03 1 0 2-.01 3 .008.313 0 .388-.106.387-.4-.012-1.54-.006-3.081 0-4.622 0-.149.014-.3.023-.473a8.229 8.229 0 0 0 .962-.008c.314-.038.45.086.572.359.714 1.606 1.454 3.2 2.17 4.805a.474.474 0 0 0 .519.336c.512-.02 1.027 0 1.54 0h2.1c-.085-.207-.139-.358-.209-.5q-1.408-2.959-2.818-5.911"
          />
          <path
            data-name="Path 44661"
            d="M75.864 27.301a.83.83 0 0 0-.167-.037c-.986.08-1.234-.413-1.234-1.285 0-.757.011-1.516-.007-2.272a31.655 31.655 0 0 0-.132-3.266 4.117 4.117 0 0 0-2.878-3.621 9.81 9.81 0 0 0-7.26.635.268.268 0 0 0-.122.227c.231.905.478 1.8.731 2.736.161-.059.279-.1.4-.147a8.261 8.261 0 0 1 3.494-.64 2.681 2.681 0 0 1 1.015.244 1.8 1.8 0 0 1 1.035 1.388 2.5 2.5 0 0 1-.07 1.016.235.235 0 0 1-.075.008c-.088-.019-.173-.046-.261-.068a8.088 8.088 0 0 0-3.738-.13 3.789 3.789 0 0 0-3.184 2.875 4.364 4.364 0 0 0 1.25 4.577c1.665 1.48 5.356 1.357 6.637-.667l.122-.2a2.488 2.488 0 0 0 1.334 1.476 4.616 4.616 0 0 0 2.908.162c.084-.018.185-.2.193-.3.024-.4.01-.8.01-1.194Zm-5.087-2.087a2.173 2.173 0 0 1-2.642 2.279 1.374 1.374 0 0 1-1.2-1.257 1.534 1.534 0 0 1 .955-1.74 3.939 3.939 0 0 1 1.938-.072c.122.016.252.045.376.07.586.122.585.122.574.719Z"
          />
          <path
            data-name="Path 44662"
            d="M119.226 27.264c-.746-.016-.991-.222-1.082-.959a9.218 9.218 0 0 1-.032-.96c-.031-1.6.042-3.216-.113-4.807a4.185 4.185 0 0 0-3.194-3.826 8.76 8.76 0 0 0-4.641-.031c-.839.2-1.652.512-2.5.781l.806 2.964.4-.158a7.508 7.508 0 0 1 3.914-.573 1.95 1.95 0 0 1 1.537 2.612 8.767 8.767 0 0 0-3.574-.3 4 4 0 0 0-3.457 2.351 4.522 4.522 0 0 0 .424 4.511 4.219 4.219 0 0 0 3.974 1.655 4.023 4.023 0 0 0 3.358-1.732c.009-.013.045-.008.089-.015a2.389 2.389 0 0 0 1.448 1.444 4.772 4.772 0 0 0 2.731.1.317.317 0 0 0 .207-.218c.015-.934.01-1.868.01-2.842h-.3m-6.76.267a1.541 1.541 0 0 1-1.847-1.892 1.464 1.464 0 0 1 1.292-1.24 4.815 4.815 0 0 1 2.31.209c.143.045.209.332.31.507a2.316 2.316 0 0 1-2.064 2.417Z"
          />
          <path
            data-name="Path 44663"
            d="M129.324 25.589a2.261 2.261 0 0 1-4.294.365 6.381 6.381 0 0 1-.35-3.923 4.549 4.549 0 0 1 .538-1.426 2.082 2.082 0 0 1 2.084-1.047 2.13 2.13 0 0 1 1.911 1.341c.1.221.17.454.259.694h3.381a5.7 5.7 0 0 0-2.2-4.145 6.024 6.024 0 0 0-4.585-.985 5.522 5.522 0 0 0-4.466 3.249 9.145 9.145 0 0 0-.674 5.008 7.316 7.316 0 0 0 .929 2.913 5.983 5.983 0 0 0 6.377 2.8 5.452 5.452 0 0 0 4.683-5.345h-3.458c-.047.179-.086.339-.133.5"
          />
          <path
            data-name="Path 44664"
            d="M93.877 28.865c-.122-.61-.244-1.219-.348-1.768-.7.052-1.33.146-1.966.139a1.429 1.429 0 0 1-1.607-1.488 7.9 7.9 0 0 1-.054-.883v-4.734c0-.122.015-.244.026-.418h3.62c0-.985.007-1.92-.013-2.855 0-.075-.2-.209-.305-.21-.873-.017-1.746-.01-2.619-.01h-.732v-3.853h-3.226c-.023.122-.052.235-.063.348-.109 1.059-.222 2.119-.315 3.18-.021.243-.093.335-.345.328-.588-.016-1.177 0-1.829 0 0 .912-.006 1.77.011 2.628 0 .071.153.188.244.2a10 10 0 0 0 1.341.145c.41.006.5.17.5.556a155.77 155.77 0 0 0 0 4.736 13.455 13.455 0 0 0 .169 2.029 3.827 3.827 0 0 0 3.351 3.425 8.637 8.637 0 0 0 3.887-.238c.351-.094.557-.2.4-.61a3.887 3.887 0 0 1-.124-.651"
          />
          <path
            data-name="Path 44665"
            d="M82.721 27.178a1.06 1.06 0 0 1-1.148-.962 1.027 1.027 0 0 1 0-.124 10.94 10.94 0 0 1-.048-1.115v-14.06c0-.34-.072-.47-.444-.46-.911.028-1.828.01-2.734.01h-.538c-.007.174-.017.289-.017.4q0 7.315.006 14.636a11.065 11.065 0 0 0 .156 1.839 3.139 3.139 0 0 0 2.682 2.926 18.574 18.574 0 0 0 2.871.037.329.329 0 0 0 .222-.235c.016-.961.01-1.92.01-2.891-.374 0-.693.012-1.013 0"
          />
          <path
            data-name="Path 44666"
            d="M103.06 16.401a4.344 4.344 0 0 0-3.425 1.884 2.711 2.711 0 0 0-1.915-1.65 5.274 5.274 0 0 0-2.58.021v2.979c1.206.07 1.395.269 1.415 1.453v8.668c0 .161.015.323.023.488h3.708v-6.96a8.113 8.113 0 0 1 .07-1.074 2.528 2.528 0 0 1 2.7-2.343 2.6 2.6 0 0 1 .357.051 13.1 13.1 0 0 1 1.323.351l1.238-3.092a4.911 4.911 0 0 0-2.916-.775"
          />
        </g>
      </svg>
    </div>
  );
}

export default Loader;
