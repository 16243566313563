import React, { useContext } from 'react';

import PaypalLogo from '../assets/images/connect-paypal.png';
import PassIcon from '../assets/images/icon-pass.svg';
import SettingIcon from '../assets/images/icon-setting.svg';
import LockIcon from '../assets/images/icon-lock.svg';
import UserContext from '../context/userContext';

function PaypalConnect({ modal = false, onConnect, onLater }) {
  const [user] = useContext(UserContext);

  return (
    <div className="mx-auto max-w-[812px]">
      <div className="text-center">
        {modal && (
          <h2 className="text-2xl font-bold text-primary-200">
            Just one click to Complete installation and Start syncing
          </h2>
        )}
        {user?.paypal_email ? (
          <p className="text-lg font-bold text-[#222429]">
            Connected PayPal Account
          </p>
        ) : (
          <p className="mt-11 text-lg font-bold text-[#222429]">
            Connect To Your Paypal Merchant Account.
          </p>
        )}
        <img className="mx-auto mt-4" src={PaypalLogo} alt="" />

        {user?.paypal_email ? (
          <p className="text-sm text-[#8FA1BF] mt-4">
            Automatically syncing your Orders to PayPal is Activated
          </p>
        ) : (
          <>
            <p className="text-sm text-[#8FA1BF] mt-4">
              Link Your Paypal For Automatic Trackings Sync
            </p>
            <p className="text-sm text-[#566381] max-w-md mx-auto mt-3">
              We will receive access limited to adding tracking details only.
              The connection is verified and secured by PayPal and Shopify.
              Click the button below to connect your PayPal account.
            </p>
          </>
        )}

        {user?.paypal_email && (
          <div className="mt-10">
            <svg
              className="mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="25.319"
              height="25.319"
            >
              <g data-name="Group 49776">
                <path
                  d="M12.659 25.319A12.659 12.659 0 1 0 0 12.659a12.659 12.659 0 0 0 12.659 12.66Z"
                  fill="#00d8ae"
                />
                <path
                  data-name="Path"
                  d="M17.135 8.184a1.266 1.266 0 1 1 1.79 1.79l-7.157 7.157a1.266 1.266 0 0 1-1.79 0l-3.585-3.576a1.266 1.266 0 0 1 1.79-1.79l2.686 2.685Z"
                  fill="#f8f7f7"
                />
              </g>
            </svg>
            <div className="bg-[#F4F3FF] w-[286px] mx-auto text-primary-200 font-medium rounded-md py-4 mt-6 ">
              {user?.paypal_email}
            </div>
          </div>
        )}
      </div>

      <div className="mt-6 text-center space-y-8 flex flex-col items-center">
        <button
          type="button"
          className="text-white py-1 px-10 bg-primary-100 rounded-md mt-4 hover:opacity-90 text-sm leading-4 font-semibold inline-flex items-center space-x-5  justify-center h-11 w-[286px]"
          onClick={onConnect}
        >
          <img src={require('../assets/images/paypal-logo.png')} alt="" />
          <span>
            {user?.paypal_email
              ? 'Change PayPal Account'
              : 'Connect With PayPal'}
          </span>
        </button>

        {modal && (
          <button
            className="border border-[#8FA1BF] rounded-md text-sm leading-4 text-[#8FA1BF] py-3.5 px-10"
            type="button"
            onClick={onLater}
          >
            Set up Later
          </button>
        )}
      </div>

      <div className="mt-12 md:mt-20 grid lg:grid-cols-3 gap-11">
        <div className="flex space-x-5">
          <img className="h-5 flex-shrink-0" src={PassIcon} alt="" />
          <div className="space-y-3">
            <h3 className="text-sm font-semibold text-[#222429]">
              Privacy First
            </h3>
            <p className="text-xs text-[#8FA1BF]">
              We will never ask for your PayPal login or password
            </p>
          </div>
        </div>
        <div className="flex space-x-5">
          <img className="h-5 flex-shrink-0" src={SettingIcon} alt="" />
          <div className="space-y-3">
            <h3 className="text-sm font-semibold text-[#222429]">
              Paypal Official API
            </h3>
            <p className="text-xs text-[#8FA1BF]">
              Supported by PayPal. We don’t use human virtual assistants and
              have no ability to move funds on your account.
            </p>
          </div>
        </div>
        <div className="flex space-x-5">
          <img className="h-5 flex-shrink-0" src={LockIcon} alt="" />
          <div className="space-y-3">
            <h3 className="text-sm font-semibold text-[#222429]">
              Secure Storage
            </h3>
            <p className="text-xs text-[#8FA1BF]">
              All data is encrypted on secure servers
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaypalConnect;
