import React, { useEffect, useState } from "react";
import {
  isEmpty,
  getParamsFromUrlString,
  getStorage,
  saveStorage,
} from "./utils";

import { Outlet, useNavigate } from "react-router-dom";
import UserContext from "./context/userContext";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Modal from "./components/Modal";

import RecommendedApps from "./components/RecommendedApps";
import {
  getBillingCallback,
  getOrders,
  getPaypalCallback,
  getPaypalSignInLink,
  getUser,
  setPlan,
} from "./services/api";
import Loader from "./components/Loader";
import PaypalConnect from "./components/PaypalConnect";
// import NewsBar from "./components/NewsBar";
// bnbnbnbnb.myshopify.com/

function App() {
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);

  // userContext initial data
  const [user, setUser] = useState({
    // set time 60 days ago
    startDate: new Date(new Date().setDate(new Date().getDate() - 60)),
  });

  let navigate = useNavigate();

  useEffect(() => {
    const shopParam = getParamsFromUrlString("shop");
    const codeParam = getParamsFromUrlString("code");
    const chargeIDParam = getParamsFromUrlString("charge_id");

    // Redirect to the /auth page if we don't have a token
    if (isEmpty(getStorage("token")))
      return navigate(`/auth?shop=${shopParam}`);

    setLoader(true);

    if (!isEmpty(getStorage("token"))) {
      getUser().then((response) => {
        isEmpty(response.data.paypal_email) &&
          isEmpty(getStorage("paypal_later")) &&
          setOpen(true);

        setUser((prev) => ({ ...prev, ...response.data }));
        // plan_name
        if (
          !response.data.hasOwnProperty("plan_name") &&
          isEmpty(chargeIDParam)
        ) {
          setPlan().then((response) => {
            window.fbq('track', 'StartTrial');
            window.location.href = response.data.link;
          });
        }
        if (!isEmpty(chargeIDParam)) {
          getBillingCallback(chargeIDParam).then((response) => {
            response.status === 200 && setLoader(false);
          });
        }

        if (response.data.hasOwnProperty("plan_name")) {
          getOrders().then((response) => {
            setUser((prev) => ({ ...prev, orders: response.data }));
            setLoader(false);
          });
        }
      });
    }

    // Paypal callback after redirect getPaypalSignInLink
    if (!isEmpty(getStorage("token")) && !isEmpty(codeParam)) {
      setLoader(true);
      getPaypalCallback(codeParam, getStorage("token")).then((response) => {
        getUser().then((response) => {
          isEmpty(response.data.paypal_email) &&
            isEmpty(getStorage("paypal_later")) &&
            setOpen(true);

          setUser((prev) => ({ ...prev, ...response.data }));

          getOrders().then((response) => {
            setUser((prev) => ({ ...prev, orders: response.data }));
            setLoader(false);
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timer = setInterval(() => {
      if (!isEmpty(localStorage.getItem("sync_interval"))) {
        const age = new Date(localStorage.getItem("sync_interval")).getTime();
        const now = new Date().getTime();

        if (now >= age) {
          localStorage.removeItem("sync_interval");
          setUser((prev) => ({
            ...prev,
            syncDisabled: false,
          }));
        } else {
          setUser((prev) => ({
            ...prev,
            syncDisabled: true,
          }));
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const paypalConnectHandler = async () => {
    const response = await getPaypalSignInLink();
    window.location.href = response.data.link;
  };
  const paypalLaterHandler = () => {
    saveStorage("paypal_later", true);
    setOpen(false);
  };

  return loader ? (
    <div className="h-screen flex items-center justify-center">
      <Loader />
    </div>
  ) : (
    <UserContext.Provider value={[user, setUser]}>
      <Modal open={open && isEmpty(user.paypal_email)} setOpen={setOpen}>
        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6 sm:py-10">
          <PaypalConnect
            modal={true}
            onConnect={paypalConnectHandler}
            onLater={paypalLaterHandler}
          />
        </div>
      </Modal>
      <div className="dashboard-wrapper">
        <Navbar />

        <div className="dashboard-body">
          <Sidebar />
          <div className="dashboard-content">
            <div className="w-full flex-1">
              <Outlet />
            </div>

            <RecommendedApps />
            <Footer />
          </div>
        </div>
      </div>
    </UserContext.Provider>
  );
}

export default App;
