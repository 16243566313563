import React, { useEffect, useState, useContext } from 'react';
import { Tab } from '@headlessui/react';
import Search from '../components/Search';
import Empty from '../components/Empty';

import { getOrdersRange } from '../services/api';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import UserContext from '../context/userContext';
import { useSearchParams } from 'react-router-dom';

const tabs = [
  {
    title: 'All PayPal Orders',
    status: 'all',
  },
  {
    title: 'Synced PayPal Orders',
    status: 'submitted',
  },
  {
    title: 'Pending sync PayPal Orders',
    status: 'pending',
  },
  {
    title: 'Errors',
    status: 'error',
  },
];

function OrdersPage() {
  const [user, setUser] = useContext(UserContext);

  const [uOrder, setUOrder] = useState([]);

  let [searchParams] = useSearchParams();

  const [perPage] = useState(10);
  const [pages, setPages] = useState();
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [activeItem, setActiveItem] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const pagesCompute = (count) => {
    const pages = Math.ceil(count / perPage);
    let arr = [];
    for (let i = 0; i < pages; i++) arr.push(i);
    setPages(arr);

    arr = arr.slice(currentPage * perPage, currentPage * perPage + perPage);
    setItems(arr);
  };

  const fetchOrders = (
    currentPage,
    type = tabs[0].status,
    search = searchParams.get('search') || searchValue
  ) => {
    const skip =
      currentPage <= 1
        ? perPage * currentPage
        : perPage * currentPage - perPage;

    getOrdersRange(perPage, skip, type, search).then((response) => {
      setUOrder((prev) => ({ ...prev, orders: response.data }));
      pagesCompute(response.data.count);
    });
  };

  useEffect(() => {
    fetchOrders(0, tabs[activeTab].status);
    setActiveItem(0);
  }, [activeTab, searchParams]);

  useEffect(() => {
    if (uOrder.orders) pagesCompute(uOrder.orders.count);
  }, [currentPage, activeTab]);

  const changePageHandler = (page) => {
    fetchOrders(page);
    setActiveItem(page);
  };

  const prev = () => {
    if (currentPage !== 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const next = () => {
    if ((currentPage + 1) * perPage < pages.length)
      setCurrentPage((prev) => prev + 1);
  };

  const submitHandler = (e) => {
    fetchOrders(activeItem, tabs[activeTab].status, e);
    setSearchValue(e);
    setCurrentPage(0);
  };

  return (
    <>
      <h2 className="text-2xl font-medium">Orders</h2>
      <div className="card mt-3.5">
        <Tab.Group
          defaultIndex={0}
          onChange={(index) => {
            setActiveTab(index);
          }}
        >
          <div className="flex flex-wrap justify-between items-center gap-6 lg:gap-14">
            <Tab.List className="tabs">
              {tabs.map((tab) => (
                <Tab
                  key={tab.status}
                  className={({ selected }) =>
                    selected
                      ? 'tab text-[#000] decoration-primary-100'
                      : 'tab text-[#A0AEC0] decoration-transparent'
                  }
                >
                  {tab.title}
                </Tab>
              ))}
            </Tab.List>

            <Search onSubmit={submitHandler} />
          </div>
          <Tab.Panels className="mt-6">
            <div className="table-wrapper overflow-auto">
              {uOrder && uOrder.orders && uOrder.orders.count !== 0 ? (
                <table className="table">
                  <thead className="table-header">
                    <tr>
                      <th className="table-header-item">Order ID</th>
                      <th className="table-header-item">Order number</th>
                      <th className="table-header-item">
                        Paypal transaction id
                      </th>
                      <th className="table-header-item">tracking number</th>
                      <th className="table-header-item">carrier</th>
                      <th className="table-header-item">Status</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {uOrder.orders.count &&
                      uOrder.orders.orders.map((order, index) => (
                        <tr key={index}>
                          <td className="table-body-item">
                            <a
                              className="underline text-blue-500"
                              href={`https://${user.name}/admin/orders/${order.order_id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {order.order_id}
                            </a>
                          </td>
                          <td className="table-body-item">
                            {order.order_name}
                          </td>
                          <td className="table-body-item">
                            <a
                              className="underline text-blue-500"
                              href={`https://www.paypal.com/activity/payment/${order.paypal_transaction}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {order.paypal_transaction}
                            </a>
                          </td>
                          <td className="table-body-item">
                            <a
                                className="underline text-blue-500"
                                href={`https://t.17track.net/en#nums=${order.tracking_number}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                              {order.tracking_number}
                            </a>
                          </td>
                          <td className="table-body-item">{order.carrier}</td>
                          <td className="table-body-item ">
                            <span
                              className={`capitalize ${
                                order.status === 'submitted'
                                  ? 'text-green-600'
                                  : 'text-red-500'
                              }`}
                            >
                              {order.status}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <div className="py-32">
                  <Empty />
                </div>
              )}
            </div>

            {uOrder.orders && uOrder.orders.count !== 0 && (
              <div className="mt-4">
                <div className="flex justify-center">
                  <ul className="pagination">
                    <li
                      className={`pagination-item ${
                        currentPage === 0 && 'opacity-50'
                      }`}
                      onClick={prev}
                    >
                      <HiChevronLeft className="text-[#371AEE] text-2xl" />
                    </li>

                    {items.map((item, index) => (
                      <li
                        key={item}
                        className={`pagination-item ${
                          activeItem === item && 'active'
                        }`}
                        onClick={() => changePageHandler(item)}
                      >
                        {item + 1}
                      </li>
                    ))}

                    {pages &&
                      (currentPage + 1) * perPage + perPage < pages.length && (
                        <>
                          <li className="pagination-item">...</li>
                          <li
                            className={`pagination-item ${
                              activeItem === pages.length && 'active'
                            }`}
                            onClick={() => changePageHandler(pages.length)}
                          >
                            {pages && pages.length}
                          </li>
                        </>
                      )}

                    {pages && (
                      <li
                        className={`pagination-item ${
                          (currentPage + 1) * perPage > pages.length &&
                          'opacity-50'
                        }`}
                        onClick={next}
                      >
                        <HiChevronRight className="text-[#371AEE] text-2xl" />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}

export default OrdersPage;
