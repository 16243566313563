import React, { useState, useContext } from 'react';
import emailTemplate from '../assets/images/email-template.png';
import emailImg from '../assets/images/email-img.png';
import { getUser, setEmailPreferences } from '../services/api';
import UserContext from '../context/userContext';
import { useNavigate } from 'react-router-dom';
import LoaderIcon from '../components/LoaderIcon';

const intervals = [
  { id: 'none', title: 'None' },
  { id: 'daily', title: 'Daily' },
  { id: 'weekly', title: 'Weekly' },
  { id: 'monthly', title: 'Monthly' },
];

function AutoRoutingPage() {
  let navigate = useNavigate();

  const [user, setUser] = useContext(UserContext);
  const [intervalState, setIntervalState] = useState(user.update_schedule);
  const [email, setEmail] = useState(user.update_email);
  const [loader, setLoader] = useState(false);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    console.log(intervalState);

    if (intervalState && email) {
      setLoader(true);
      setEmailPreferences(email, intervalState).then((response) => {
        if (response.status === 200) {
          getUser().then((response) => {
            setUser((prev) => ({ ...prev, ...response.data }));
            setLoader(false);
            navigate('/');
          });
        }
      });
    }
  };
  return (
    <div>
      <h2 className="text-2xl font-medium">Email Reports</h2>
      <div className="card mt-4 ">
        <div className="grid lg:grid-cols-[600px,1fr]">
          <div className="lg:p-12">
            <div className="text-center">
              <img className="mx-auto mb-7" src={emailImg} alt="" />
              <h4 className="text-xl font-medium">
                Set Your Email Notifications
              </h4>
              <p className="mt-3 text-lg text-[#566381]">
                You can receive email updates about Paltrack activity. Just
                choose the email address you want to receive these on and the
                interval. If you put the interval as ‘None’ it turns off email
                notifications.
              </p>
            </div>

            <div className="mt-10">
              <h4 className="text-xl font-medium ">Interval</h4>
              <p className="text-sm font-medium text-[#767C89] mt-1">
                How often do you prefer to receive your Email Notifications
              </p>

              <div className="mt-6 gap-2 flex justify-between">
                {intervals.map((interval) => (
                  <div key={interval.id} className="flex items-center">
                    <input
                      id={interval.id}
                      name="notification-method"
                      type="radio"
                      defaultChecked={interval.id === user.update_schedule}
                      value={interval.id}
                      className="focus:ring-primary-200 h-5 w-5 text-primary-200 border-gray-300"
                      onChange={(e) => setIntervalState(e.target.value)}
                    />
                    <label
                      htmlFor={interval.id}
                      className="ml-1 md:ml-3 block text-sm font-medium text-[#364050] cursor-pointer"
                    >
                      {interval.title}
                    </label>
                  </div>
                ))}
              </div>

              <form className="mt-8" onSubmit={onSubmitHandler}>
                <input
                  type="email"
                  className="shadow-sm focus:ring-primary-100 focus:border-primary-100 block w-full sm:text-sm border-[#E1E1F4] rounded-md bg-[#F4F3FF] py-3.5 px-5"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <div className="flex items-center space-x-3 mt-3">
                  <p className="text-[11px] text-[#566381]">
                    (P.S If have multiple stores, adding the same email to each
                    of the stores will bundle your reports from different stores
                    into one email.)
                  </p>
                  <button
                    className="bg-primary-200 rounded-md font-medium text-white py-3.5 px-12 inline-flex items-center"
                    type="submit"
                  >
                    {loader && <LoaderIcon />} Save
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div>
            <img src={emailTemplate} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutoRoutingPage;
