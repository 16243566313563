import React from "react";

function TrackCard({ title, amount = 0, range, percent = "", img }) {
  return (
    <div
      className="bg-primary-200 p-5 text-white rounded-md space-y-3.5 bg-no-repeat bg-right-bottom"
      style={{ backgroundImage: `url(${img})` }}
    >
      <h4 className="text-lg font-semibold leading-5">{title}</h4>
      <p className="text-[40px] font-bold text-[#82FAAC]">{amount}</p>
      <div className="flex items-center space-x-7">
        <p className="text-sm font-semibold">{range}</p>
        <p className="text-sm font-bold text-[#82FAAC]">{percent}</p>
      </div>
    </div>
  );
}

export default TrackCard;
