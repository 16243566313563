import React, { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FiChevronDown, FiSettings, FiLogOut } from "react-icons/fi";
import { Link } from "react-router-dom";
import UserContext from "../context/userContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ProfileDropDown() {
  const logoutHandler = () => {
    sessionStorage.removeItem("token");
    window.location.href = "/";
  };
  const [user] = useContext(UserContext);
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="inline-flex items-center space-x-2.5">
        <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
          <p className="text-sm font-semibold text-primary-200">
            {user.name && user.name.slice(0, 2).toUpperCase()}
          </p>
        </div>
        <FiChevronDown className="text-white" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/settings"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "px-4 py-2 text-sm space-x-2 flex items-center"
                  )}
                >
                  <FiSettings className="text-lg" />
                  <span>Settings</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={logoutHandler}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "px-4 py-2 text-sm space-x-2 flex items-center w-full"
                  )}
                >
                  <FiLogOut className="text-lg" />
                  <span>Logout</span>
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    // <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
    //   <p className="text-sm font-semibold text-primary-200">YO</p>
    // </div>
  );
}

export default ProfileDropDown;
