import React, { useState, useEffect, useContext } from 'react';
import routeImage from '../assets/images/route-image.png';
import { Switch } from '@headlessui/react';
import {
  getCarriers,
  getUser,
  setRouting,
  toggleRouting,
} from '../services/api';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function AutoRoutingPage() {
  const [enabled, setEnabled] = useState(false);
  const [country, setCountry] = useState();
  const [original, setOriginal] = useState('');
  const [paypalCarrier, setPaypalCarrier] = useState();
  const [carriers, setCarriers] = useState();
  const [routingRules, setRoutingRules] = useState();
  const [filteredRules, setFilteredRules] = useState();

  const formatArray = (rules) => {
    if (routingRules && carriers) {
      let arr = [];
      if (rules) {
        Object.keys(rules).forEach((item) => {
          arr.push({ original: item, paypal: rules[item] });
        });

        const countryKey = Object.keys(carriers);
        const arrWithCountry = [];
        arr.forEach((i) => {
          countryKey.forEach((k) => {
            if (Object.keys(carriers[k]).find((a) => a === i.paypal)) {
              arrWithCountry.push({ ...i, country: k });
            }
          });
        });
        setFilteredRules(arrWithCountry);
      }
    }
  };
  useEffect(() => {
    formatArray(routingRules);
  }, [carriers, routingRules]);

  useEffect(() => {
    getCarriers().then((response) => {
      setCarriers(response.data);
      getUser().then((response) => {
        setRoutingRules(response.data.routing_rules);
        setEnabled(response.data.auto_routing);
      });
    });
  }, []);

  const saveHandler = () => {
    if (original && paypalCarrier) {
      const newRules = { ...routingRules, [original]: paypalCarrier };
      setRouting(newRules);
      formatArray(newRules);
      setRoutingRules((prev) => ({ ...prev, [original]: paypalCarrier }));
    }
  };

  const switchHandler = (e) => {
    toggleRouting(e);
    setEnabled((prev) => !prev);
  };

  const ruleDeleteHandler = (e) => {
    const asArr = Object.entries(routingRules);
    const filtered = asArr.filter(([key]) => key !== e);

    const newArr = Object.fromEntries(filtered);

    setRouting(newArr);
    formatArray(newArr);
    setRoutingRules(newArr);
  };

  return (
    <div>
      <h2 className="text-2xl font-medium">Auto Routing</h2>
      <div className="card mt-4 pb-10">
        <h3 className="text-lg underline underline-offset-4 decoration-2 decoration-primary-100">
          Routing Policy
        </h3>
        <p className="mt-3 text-[#2D2D2D] text-sm">
          Add rules to auto-update carrier info before syncing to PayPal. This
          ensures that PayPal always receives a carrier name from its reference
          list and your payments are released on time.
        </p>

        <div className="mt-8 rounded-md border border-[#CDD0E3] p-6">
          <div className="grid lg:grid-cols-3 gap-4">
            <div>
              <p className="text-xs text-[#1C1C1C]">
                Enter your original carrier info:
              </p>

              <div className="mt-12">
                <label
                  htmlFor="carrier"
                  className="block text-xs font-medium text-[#74849F]"
                >
                  Original carrier
                </label>
                <input
                  type="text"
                  name="carrier"
                  className="mt-2 block w-full pl-5 pr-10 py-3 text-base border-[#CDD0E3] border-opacity-50 focus:outline-none focus:ring-primary-100 focus:border-primary-100 sm:text-sm rounded-md shadow"
                  placeholder="Type your shopify carrier"
                  onChange={(e) => setOriginal(e.target.value)}
                  value={original}
                />
              </div>
            </div>
            <div className="pt-4 text-center">
              <p className="font-semibold text-[#1C1C1C]">
                Change the carrier to
              </p>
              <img src={routeImage} alt="" className="mt-8 mx-auto" />

              <button
                className="bg-primary-200 rounded text-sm font-semibold mt-10 px-8 py-4 text-white"
                onClick={saveHandler}
              >
                Add Rule
              </button>
            </div>
            <div>
              <p className="text-xs text-[#1C1C1C]">
                Enter the final carrier info to send to PayPal:
              </p>

              <div className="mt-5">
                <label
                  htmlFor="country-specific"
                  className="block text-xs font-medium text-[#74849F]"
                >
                  Country {country}
                </label>
                <select
                  name="country-specific"
                  className="mt-2 block w-full pl-5 pr-10 py-3 text-base border-[#CDD0E3] border-opacity-50 focus:outline-none focus:ring-primary-100 focus:border-primary-100 sm:text-sm rounded-md shadow"
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option className="hidden">Country Specific</option>
                  {carriers &&
                    Object.keys(carriers).map((carrier) => (
                      <option key={carrier}>{carrier}</option>
                    ))}
                </select>
              </div>

              <div className="mt-5">
                <label
                  htmlFor="country"
                  className="block text-xs font-medium text-[#74849F]"
                >
                  Paypal Carriers
                </label>
                <select
                  name="country"
                  className="mt-2 block w-full pl-5 pr-10 py-3 text-base border-[#CDD0E3] border-opacity-70 focus:outline-none focus:ring-primary-100 focus:border-primary-100 sm:text-sm rounded-md shadow"
                  onChange={(e) => setPaypalCarrier(e.target.value)}
                >
                  <option className="hidden"> Choose a carrier</option>
                  {country &&
                    Object.keys(carriers[country]).map((carrier) => (
                      <option value={carrier} key={carrier}>
                        {carriers[country][carrier]}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <div className="flex items-center justify-between pr-10">
            <h3 className="text-lg ">Carriers</h3>

            <div className="flex items-center space-x-3">
              {enabled ? (
                <p className="text-sm font-medium">Activated</p>
              ) : (
                <p className="text-sm font-medium">Deactivated</p>
              )}
              <Switch
                checked={enabled}
                onChange={switchHandler}
                className={classNames(
                  enabled ? 'bg-[#34C759]' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </div>
          </div>

          <div className="mt-3 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-md">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-[#F0F0F4] border-b border-[#CDD0E3] border-opacity-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5-3 text-left font-normal text-xs  text-[#A0AEC0] sm:pl-6"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="p-3 text-left font-normal text-xs  text-[#A0AEC0]"
                        >
                          ORIGINAL CARRIER
                        </th>

                        <th
                          scope="col"
                          className="p-3 text-left font-normal text-xs  text-[#A0AEC0]"
                        >
                          PAYPAL CARRIERS
                        </th>
                        <th
                          scope="col"
                          className="p-3 text-left font-normal text-xs  text-[#A0AEC0]"
                        >
                          COUNTRY
                        </th>

                        <th
                          scope="col"
                          className="p-3 text-left font-normal text-xs  text-[#A0AEC0]"
                        >
                          ACTIONS
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {filteredRules &&
                        filteredRules.map((rule, index) => (
                          <tr key={index}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {index + 1}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {rule.original}
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {rule.paypal}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {rule.country}
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <button
                                className="bg-[#EB003C] text-white rounded py-2 px-5 text-sm font-medium"
                                onClick={() => ruleDeleteHandler(rule.original)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutoRoutingPage;
