import React, { useState } from "react";
import { FiX } from "react-icons/fi";

function Search({ onSubmit, variant }) {
  const [value, setValue] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    onSubmit(value);
  };
  const clearHandler = () => {
    onSubmit("");
    setValue("");
  };

  return (
    <form
      onSubmit={submitHandler}
      className={`h-10 relative ${variant === "wide" ? "w-[400px]" : "w-72"}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.565"
        height="14.562"
        className="absolute top-1/2 transform -translate-y-1/2 left-3.5"
      >
        <path
          data-name="svgexport-3 (1)"
          d="m14.339 13.247-4.035-4.032a5.738 5.738 0 1 0-1.093 1.093l4.035 4.035a.782.782 0 0 0 1.093 0 .773.773 0 0 0 0-1.096ZM1.587 5.757a4.172 4.172 0 1 1 4.172 4.172 4.172 4.172 0 0 1-4.172-4.172Z"
          fill="#a0aec0"
        />
      </svg>
      <input
        type="text"
        placeholder="Search"
        className="w-full h-full rounded-md border border-[#d0dae5] pl-10 pr-24 focus:outline-none placeholder:text-[#A0AEC0]"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <div className="absolute top-1 right-1 bottom-1 flex items-center">
        {value && (
          <button type="button" className="w-6 h-full" onClick={clearHandler}>
            <FiX className="text-md font-medium text-gray-400" />
          </button>
        )}
      </div>
    </form>
  );
}

export default Search;
