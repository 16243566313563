import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { HiOutlineCalendar, HiChevronDown } from 'react-icons/hi';
import 'react-datepicker/dist/react-datepicker.css';
import UserContext from '../context/userContext';
import { getStorage, isEmpty } from '../utils';
import { getOrdersStats } from '../services/api';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const days = [
  { span: 'all', title: 'All' },
  { span: '24h', title: 'Last 24 Hours' },
  { span: '7d', title: 'Last 7 Days' },
  { span: '30d', title: 'Last 30 Days' },
];

function DateRange() {
  const [selected, setSelected] = useState(days[2]);
  const [user, setUser] = useContext(UserContext);

  useEffect(() => {
    if (!isEmpty(getStorage('token')) && user.hasOwnProperty('plan_name')) {
      getOrdersStats(selected.span).then((response) => {
        setUser((prev) => ({
          ...prev,
          ordersStats: response.data,
          ordersStatsRange: selected,
        }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="h-12 py-2 px-4 bg-white rounded-md flex items-center space-x-3 cursor-pointer  focus:outline-none">
              <HiOutlineCalendar className="text-xl text-primary-100" />
              <div className="text-sm flex items-center space-x-2">
                <p className="text-[#A6AEBC]">Date range</p>
                <p className="text-primary-100 font-medium flex items-center">
                  {selected.title} <HiChevronDown className="ml-1 text-lg" />
                </p>
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 right-0 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {days.map((day) => (
                  <Listbox.Option
                    key={day.span}
                    value={day}
                    className={({ active, selected }) =>
                      classNames(
                        active ? 'text-white bg-primary-100' : 'text-gray-900',
                        'cursor-pointer select-none relative py-2 pl-3 pr-9'
                      )
                    }
                  >
                    {day.title}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default DateRange;
