import React, { useState } from "react";
import { HiExclamationCircle, HiX } from "react-icons/hi";
import { Transition } from "@headlessui/react";
import { isEmpty } from "../utils";

function Alert({ variant, message }) {
  const [isOpen, setIsOpen] = useState(true);

  const closeHandler = () => {
    setIsOpen(false);
    localStorage.setItem("alert", false);
  };

  return (
    isEmpty(localStorage.getItem("alert")) && (
      <Transition
        as="div"
        show={isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={`alert ${variant}`}
      >
        <div className="flex items-center space-x-3">
          <HiExclamationCircle className="text-xl" />
          <span>{message}</span>
        </div>

        <button type="button" onClick={closeHandler}>
          <HiX className="text-lg" />
        </button>
      </Transition>
    )
  );
}

export default Alert;
