import React from 'react';
import BackInTimes from '../components/BackInTimes';

function BackInTime() {
  return (
    <div className="card py-10 md:py-[150px]">
      <BackInTimes />
    </div>
  );
}

export default BackInTime;
