import React from 'react';
import { HiCheckCircle } from 'react-icons/hi';

function TrackItem({ number, carrier, status }) {
  return (
    <div className="rounded-md border border-[#CDD0E3] border-opacity-75 bg-[#F0F0F4] py-3 pl-4 pr-5 flex flex-col items-start lg:flex-row lg:items-center gap-4 md:gap-6">
      <div>
        <p className="text-xs text-[#919EAB] leading-4">TRACKING NUMBER</p>
        <p className="text-sm text-[#212B36 leading-4] mt-1 md:mt-3">
          {number >= 12 ? number.slice(0, 12) + '...' : number}
        </p>
      </div>
      <div className="flex items-start w-full">
        <div className="">
          <p className="text-xs text-[#919EAB] leading-4">CARRIER</p>
          <p className="text-sm text-[#212B36 leading-4] mt-1 md:mt-3 whitespace-nowrap">
            {carrier}
          </p>
        </div>
        <div className="flex-1 text-right">
          {status === 'submitted' && (
            <HiCheckCircle className="text-[#00D8AE] text-3xl inline" />
          )}
          {status === 'pending' && (
            <HiCheckCircle className="text-yellow-300 text-3xl inline" />
          )}
          {status === 'error' && (
            <HiCheckCircle className="text-red-500 text-3xl inline" />
          )}
        </div>
      </div>
    </div>
  );
}

export default TrackItem;
