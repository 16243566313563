import axios from 'axios';
import {
  REACT_APP_SHOPIFY_AUTH_REDIRECT,
  REACT_APP_SHOPIFY_BILLING_REDIRECT,
  REACT_APP_PAYPAL_REDIRECT_PAGE,
} from '../constants';
import { getStorage } from '../utils';

const API_ORIGIN = 'https://api.paltrack.app/';

const api = axios.create({
  baseURL: API_ORIGIN,
});

// Shopify requests
export const getAuthLink = (shopName) => {
  return api.get(
    `/shopify?shop=${shopName}&redirect=${REACT_APP_SHOPIFY_AUTH_REDIRECT}`
  );
};

export const getToken = (params) => {
  return api.get('/auth/callback', { params });
};

export const getUser = () => {
  return api.get('/authenticated/me', {
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

export const getOrders = () => {
  return api.get('/authenticated/orders', {
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

export const getOrdersRange = (limit, skip, status, search) => {
  return api.get('/authenticated/orders', {
    params: { limit, skip, status, search },
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

export const searchOrders = (search) => {
  return api.get('/authenticated/orders', {
    params: { search },
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

export const getOrdersStats = (span) => {
  return api.get(`/authenticated/orders/stats?span=${span}`, {
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

export const getOrdersError = () => {
  return api.get(`authenticated/orders?status=error`, {
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

// shopify billing requests
export const setPlan = () => {
  return api.post(
    `/authenticated/billing/plan?redirect=${REACT_APP_SHOPIFY_BILLING_REDIRECT}`,
    { plan_name: 'ONEPLAN' },
    {
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

export const getBillingCallback = (charge_id) => {
  return api.get('/authenticated/billing/callback', {
    params: { charge_id },
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

// Paypal requests
export const getPaypalSignInLink = () => {
  return api.get(
    `/authenticated/paypal/signin?redirect=${REACT_APP_PAYPAL_REDIRECT_PAGE}`,
    {
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

export const getPaypalCallback = (code) => {
  return api.get('/authenticated/paypal/auth/callback', {
    params: { code },
    headers: {
      Authorization: `Bearer ${getStorage('token')}`,
    },
  });
};

// Multi trigger
export const multiTrigger = (from, to) => {
  return api.post(
    '/authenticated/orders/tracking/multi',
    {},
    {
      params: { from, to },
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

// Routing
export const setRouting = (data) => {
  return api.post(
    `/authenticated/auto_routing`,
    {
      mapping: data,
    },
    {
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

export const toggleRouting = (data) => {
  return api.post(
    `/authenticated/auto_routing/toggle`,
    {
      enable: data,
    },
    {
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

// Get carriers
export const getCarriers = () => {
  return axios.get(`carriers.json`);
};

// Email Reports
export const setEmailPreferences = (email, interval) => {
  return api.post(
    '/authenticated/update/email_pref',
    {
      update_email: email,
      update_schedule: interval,
    },
    {
      headers: {
        Authorization: `Bearer ${getStorage('token')}`,
      },
    }
  );
};

// data: { update_email: 'jpow@fed.gov', update_schedule: 'daily' },
