import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../assets/images/logo.svg';
import ProfileDropDown from './ProfileDropDown';
import Search from './Search';
import { useNavigate } from 'react-router-dom';

import { HiMenuAlt2 } from 'react-icons/hi';

function Navbar() {
  let navigate = useNavigate();

  const submitHandler = (e) => {
    navigate('/orders?search=' + e);
  };

  const sidebarHideHandler = () => {
    document.documentElement.classList.toggle('sidebar-active');
  };

  return (
    <div className="bg-primary-200 h-16 flex items-center justify-between p-4 md:px-11 relative z-20">
      <button type="button" onClick={sidebarHideHandler} className="md:hidden">
        <HiMenuAlt2 className="w-6 h-6 text-white" />
      </button>

      <Link to="/">
        <img src={Logo} alt="Paltrack" />
      </Link>

      <div className="hidden md:block">
        <Search onSubmit={submitHandler} variant="wide" />
      </div>

      <ProfileDropDown />
    </div>
  );
}

export default Navbar;
