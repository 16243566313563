// Check if the object, string, array is empty
export const isEmpty = (input) => {
  if (input === null || input === undefined) return true;

  if (typeof input === "string" && input.trim() === "") return true;

  if (Array.isArray(input) && input.length === 0) return true;

  if (typeof input === "object" && Object.keys(input).length === 0) return true;

  return false;
};

// Get any parameter from url string
export const getParamsFromUrlString = (name) => {
  const urlObject = new URL(window.location.href);
  return urlObject.searchParams.get(name) || "";
};

export const saveStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

export const getStorage = (key) => {
  return window.sessionStorage.getItem(key);
};
