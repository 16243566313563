import React from "react";
import emptySmile from "../assets/images/empty-smile.png";

function Empty() {
  return (
    <div className="text-center">
      <img className="mx-auto" src={emptySmile} alt="" />
      <p className="text-lg leading-6 font-medium mt-2.5">Ooops!</p>
      <p className="mt-3 text-lg">It looks like this list is empty</p>
    </div>
  );
}

export default Empty;
