import React from "react";

function AppCard({ title, subtitle, text, link, img }) {
  return (
    <div className="border border-gray-300 bg-white py-4 px-7 rounded-md flex flex-col">
      <div className="flex space-x-4">
        <img
          src={img}
          alt={title}
          className="w-[68px] h-[68px] object-cover flex-shrink-0"
        />
        <div className="pt-1.5">
          <h4 className="text-lg leading-6 font-bold">{title}</h4>
          <p className="text-sm text-[#8FA1BF] mt-2 leading-4">{subtitle}</p>
        </div>
      </div>
      <div className="flex flex-col h-full">
        <div className="flex-1">
          <p className="mt-4 text-[#566381] text-sm leading-[18px]">{text}</p>
        </div>

        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-secondary text-white flex justify-center items-center text-sm font-semibold h-9 rounded-md mt-4 hover:opacity-90 transition-opacity"
        >
          Try it free now
        </a>
      </div>
    </div>
  );
}

export default AppCard;
